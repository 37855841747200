<header class="sticky top-0 z-10">
  <div class="container mx-auto flex items-center justify-between">
    <!-- Logo and Name -->
    <div class="flex items-center">
      <button routerLink="/vigi" class="focus:outline-none">
        <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5M12 19l-7-7 7-7"></path>
        </svg>
      </button>
      <span class="text-xl ml-3 font-bold">Sales pitch</span>
    </div>
    <!-- Kebab Icon -->
    <div class="flex items-center">
      <button (blur)="toggleDropdown()" (click)="toggleDropdown()" class="focus:outline-none toggle-button">
        <img class="h-6 w-6" src="../../../../assets/icons/menu.png" />
      </button>
      <div [ngClass]="{'dropdown-open': isDropdownOpen}" class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
        <div class="navigation-options">
          <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="title-section h-10 flex items-center">
  <span class="ml-6">Customer profile details</span>
</div>
<div class="sales-form" [formGroup]="salesForm">

  <!-- Gender and Occupation Container -->
  <div class="flex justify-between items-end">
    <div class="flex flex-col">
      <label class="block header-label">Gender</label>
      <div class="container flex gender-toggle">
        <label class="inline-flex product-toggle items-center cursor-pointer justify-center">
          <span [ngClass]="{
            'bg-purple text-white': selectedGender === 'male',
            'bg-white text-black border border-l-gray-50': selectedGender !== 'male'
          }" class="text-center rounded-l-md" (click)="selectGender('male')">
            Male
          </span>
          <span [ngClass]="{
            'bg-purple text-white': selectedGender === 'female',
            'bg-white text-black border border-l-gray-50': selectedGender !== 'female'
          }" class="text-center" (click)="selectGender('female')">
            Female
          </span>
          <span [ngClass]="{
            'bg-purple text-white': selectedGender === 'others',
            'bg-white text-black border border-l-gray-50': selectedGender !== 'others'
          }" class="text-center rounded-r-md" (click)="selectGender('others')">
            Others
          </span>
        </label>
      </div>
    </div>
    <div class="flex flex-col occupation-dropdown">
      <label class="block header-label">Occupation</label>
      <p-dropdown [options]="occupations" formControlName="occupation" styleClass="w-28"></p-dropdown>
    </div>
  </div>

  <!-- Occupation and Age Container -->
  <div class="flex space-y-4 justify-between items-end">
    <div class="flex flex-col">
      <label class="block header-label">Age</label>
      <div class="relative">
        <input type="number" class="w-16 px-2 py-1 rounded-md text-center age-input" formControlName="age">
        <button type="button" (click)="decrementAge()"
          class="absolute left-1 top-1/2 transform -translate-y-1/2">-</button>
        <button type="button" (click)="incrementAge()"
          class="absolute right-1 top-1/2 transform -translate-y-1/2">+</button>
      </div>
    </div>
    <div class="flex flex-col">
      <label class="block text-center header-label">Expected Retirement Age</label>
      <div class="relative flex justify-end">
        <input type="number" class="w-full px-2 py-1 rounded-md text-center retire-age-input"
          formControlName="retirementAge">
        <button type="button" (click)="decrementRetirementAge()"
          class="absolute left-20 top-1/2 transform -translate-y-1/2">-</button>
        <button type="button" (click)="incrementRetirementAge()"
          class="absolute right-20 top-1/2 transform -translate-y-1/2">+</button>
      </div>
    </div>
  </div>

  <!-- Family Members Multi-Select Container -->
  <div class="mt-4 family-dropdown">
    <label class="block header-label">Family members other than self</label>
    <span class="chevron-down"><svg width="14" height="14" viewBox="0 0 14 14" fill="none"
        xmlns="http://www.w3.org/2000/svg" class="p-dropdown-trigger-icon p-icon" aria-hidden="true">
        <path
          d="M7.01744 10.398C6.91269 10.3985 6.8089 10.378 6.71215 10.3379C6.61541 10.2977 6.52766 10.2386 6.45405 10.1641L1.13907 4.84913C1.03306 4.69404 0.985221 4.5065 1.00399 4.31958C1.02276 4.13266 1.10693 3.95838 1.24166 3.82747C1.37639 3.69655 1.55301 3.61742 1.74039 3.60402C1.92777 3.59062 2.11386 3.64382 2.26584 3.75424L7.01744 8.47394L11.769 3.75424C11.9189 3.65709 12.097 3.61306 12.2748 3.62921C12.4527 3.64535 12.6199 3.72073 12.7498 3.84328C12.8797 3.96582 12.9647 4.12842 12.9912 4.30502C13.0177 4.48162 12.9841 4.662 12.8958 4.81724L7.58083 10.1322C7.50996 10.2125 7.42344 10.2775 7.32656 10.3232C7.22968 10.3689 7.12449 10.3944 7.01744 10.398Z"
          fill="#4b5563"></path>
      </svg></span>
    <ng-multiselect-dropdown [placeholder]="'Select family members'" [settings]="dropdownSettings"
      [data]="familyMembers" formControlName="familyMembers">
    </ng-multiselect-dropdown>
  </div>

  <!-- Annual Income and Expenditure Container -->
  <div class="mt-3 flex justify-between">
    <div class="flex flex-col">
      <label class="block header-label">Annual income (in Rs.)</label>
      <input type="text" class="px-2 py-1 rounded-md" formControlName="annualIncome" commaFormatter>
    </div>
    <div class="flex flex-col">
      <label class="block header-label">Annual expenditure (in Rs.)</label>
      <input type="text" class="px-2 py-1 rounded-md" formControlName="annualExpenditure" commaFormatter>
    </div>
  </div>

  <!-- Annual Income and Expenditure Container -->
  <div class="mt-3 flex justify-between">
    <div class="flex flex-col">
      <label class="block header-label">Investments (in Rs.)</label>
      <input type="text" class="px-2 py-1 rounded-md" formControlName="investments" commaFormatter>
    </div>
    <div class="flex flex-col mr-2">
      <label class="block header-label">Outstanding loans (in Rs.)</label>
      <input type="text" class="px-2 py-1 rounded-md" formControlName="outstandingLoans" commaFormatter>
    </div>
  </div>

  <div class="mt-4">
    <div class="flex flex-col life-goals-dropdown">
      <label class="block header-label">Life goals</label>
      <div class="container flex flex-wrap justify-center">
        <div *ngFor="let goal of lifeGoals" 
             (click)="toggleSelection(goal.item_id)" 
             [ngClass]="{
               'bg-purple text-white': selectedGoals.includes(goal.item_id),
               'bg-white text-black': !selectedGoals.includes(goal.item_id),
               'w-52':goal.item_text === 'Financial security for the family'
             }" 
             class="px-4 text-center cursor-pointer border multi-select-option-container">
          {{ goal.item_text }}
        </div>
      </div>
      
    </div>
  </div>

  <div class="mt-3 flex justify-between">
    <div class="flex flex-col">
      <label class="block header-label">Amount needed (in Rs.)</label>
      <input type="text" class="px-2 py-1 rounded-md" formControlName="amountNeeded" commaFormatter>
    </div>
    <div class="flex flex-col">
      <label class="block header-label">Existing Life Insurance</label>
      <label class="inline-flex items-center space-x-4 cursor-pointer">
        <span>Yes</span>
        <span class="relative">
          <input id="Toggle2" type="checkbox" class="hidden peer" (change)="onToggleChange()" [checked]="isYesSelected">
          <div class="w-8 h-3 rounded-full shadow bg-gray-400 peer-checked:bg-indigo-600"></div>
          <div class="absolute left-0 w-4 h-4 rounded-full shadow -inset-y-0.5 bg-purple transform peer-checked:translate-x-4 transition-transform"></div>
        </span>
        <span>No</span>
      </label>
    </div>
  </div>

  <div class="mt-4">
    <label class="block header-label">Location</label>
    <div class="flex justify-between items-center location-toggle">
      <label class="inline-flex items-center cursor-pointer justify-center">
        <span [ngClass]="{
          'bg-purple text-white': selectedLocation === 'top city',
          'bg-white text-black border border-l-gray-50': selectedLocation !== 'top city'
        }" class="px-2 text-center rounded-l-md" (click)="selectLocation('top city')">
          Top city
        </span>
        <span [ngClass]="{
          'bg-purple text-white': selectedLocation === 'small city/town',
          'bg-white text-black border border-l-gray-50': selectedLocation !== 'small city/town'
        }" class="px-2 text-center" (click)="selectLocation('small city/town')">
          Small city/Town
        </span>
        <span [ngClass]="{
          'bg-purple text-white': selectedLocation === 'rural area',
          'bg-white text-black border border-l-gray-50': selectedLocation !== 'rural area'
        }" class="px-2 text-center rounded-r-md" (click)="selectLocation('rural area')">
          Rural area
        </span>
      </label>
    </div>
  </div>
  

  <!-- Submit button -->
  <div class="mt-8 text-center flex items-center">
    <button (click)="onSubmit(false)" class="custom-button mx-auto">Submit</button>
    <p (click)="onSubmit(true)" class="absolute right-8 font-semibold font-purple">Skip to profile</p>
  </div>

  <!-- Error messages grid -->
  <div class="grid grid-cols-2 md:grid-cols-2 mt-4">
    <div *ngIf="salesForm.controls['gender'].invalid && salesForm.controls['gender'].touched" class="text-red-500">
      Please select a gender.</div>
    <div *ngIf="salesForm.controls['occupation'].invalid && salesForm.controls['occupation'].touched"
      class="text-red-500">Please select an occupation.</div>
    <div *ngIf="salesForm.controls['age'].invalid && salesForm.controls['age'].touched" class="text-red-500">Please
      enter a valid age (0-99).</div>
    <div *ngIf="salesForm.controls['retirementAge'].invalid && salesForm.controls['retirementAge'].touched"
      class="text-red-500">Please enter a valid retirement age (0-99).</div>
    <div *ngIf="salesForm.controls['familyMembers'].invalid && salesForm.controls['familyMembers'].touched"
      class="text-red-500">Please select family members.</div>
    <div *ngIf="salesForm.controls['annualIncome'].invalid && salesForm.controls['annualIncome'].touched"
      class="text-red-500">Please enter a valid annual income.</div>
    <div *ngIf="salesForm.controls['annualExpenditure'].invalid && salesForm.controls['annualExpenditure'].touched"
      class="text-red-500">Please enter a valid annual expenditure.</div>
    <div *ngIf="salesForm.controls['investments'].invalid && salesForm.controls['investments'].touched"
      class="text-red-500">Please enter a valid investment amount.</div>
    <div *ngIf="salesForm.controls['outstandingLoans'].invalid && salesForm.controls['outstandingLoans'].touched"
      class="text-red-500">Please enter a valid loan amount.</div>
    <div *ngIf="salesForm.controls['lifeGoals'].invalid && salesForm.controls['lifeGoals'].touched"
      class="text-red-500">Please select life goals.</div>
    <div *ngIf="salesForm.controls['amountNeeded'].invalid && salesForm.controls['amountNeeded'].touched"
      class="text-red-500">Please enter a valid amount needed.</div>
    <div *ngIf="salesForm.controls['healthInsurance'].invalid && salesForm.controls['healthInsurance'].touched"
      class="text-red-500">Please select an option.</div>
    <div
      *ngIf="salesForm.controls['lifeInsuranceCoverage'].invalid && salesForm.controls['lifeInsuranceCoverage'].touched"
      class="text-red-500">Please enter a valid coverage amount.</div>
    <div *ngIf="salesForm.controls['location'].invalid && salesForm.controls['location'].touched" class="text-red-500">
      Please select a location.</div>
  </div>
</div>