<header>
    <div class="container mx-auto flex items-center justify-between">
        <!-- Logo and Name -->
        <div class="flex items-center">
            <button routerLink="/home" class="focus:outline-none">
                <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5M12 19l-7-7 7-7"></path>
                </svg>
            </button>
            <span class="text-xl ml-3 font-bold">VIGI</span>
        </div>
        <!-- Kebab Icon -->
        <div class="flex items-center">
            <button  (onblur)="toggleDropdown()" (click)="toggleDropdown()" class="focus:outline-none toggle-button">
                <img class="h-6 w-6" src="../../../../assets/icons/menu.png"/>
            </button>
            <div [ngClass]="{'dropdown-open': isDropdownOpen}" class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
              <div class="navigation-options">
                <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
              </div>
            </div>
        </div>
    </div>
  </header>
<form [formGroup]="productForm" (ngSubmit)="onSubmit()">
    <div class="title-section h-10 flex items-center">
      <span class="ml-6">Product Details</span>
    </div>
    <div class="container flex justify-center mt-7 mb-7">
      <label for="Toggle3" class="inline-flex product-toggle items-center cursor-pointer justify-center">
        <input id="Toggle3" type="checkbox" class="hidden" (change)="toggleSelection()" formControlName="productType">
        <span [ngClass]="{
              'bg-purple text-white': !isHealthSelected,
              'bg-gray text-black': isHealthSelected
            }" class="px-4 py-2 w-1/2 text-center rounded-l-md">
          Life
        </span>
        <span [ngClass]="{
              'bg-purple text-white': isHealthSelected,
              'bg-gray text-black': !isHealthSelected
            }" class="px-4 py-2 w-1/2 text-center rounded-r-md">
          Health
        </span>
      </label>
    </div>
    
    
    
    <div class="container mx-auto p-4">
  
      <!-- Dropdown Container -->
      <div class="dropdown-container flex items-center rounded pl-2 mb-4 w-full h-10">
        <!-- Umbrella Icon -->
        <img src="../../../../assets/icons/life-insurance.png" alt="Placeholder" class="vigi-icon mx-auto">
        <!-- Dropdown -->
        <div class="w-full">
          <p-dropdown id="product" formControlName="product" (onChange)="onProductChange($event)" [options]="products" placeholder="Select a product"></p-dropdown>
        </div>
      </div>
  
      <div *ngIf="showOptions" class="flex justify-center items-center space-x-9 mt-9 sales-product-toggle">
        <label class="flex items-center justify-center cursor-pointer border rounded" 
               [ngClass]="{
                 ' border-gray-300': productForm.get('moduleType').value !== 'sales-pitch',
                 'bg-purple text-white border-transparent': productForm.get('moduleType').value === 'sales-pitch'
               }">
          <input type="radio" (change)="onSubmit()" formControlName="moduleType" value="sales-pitch" class="form-radio">
          <span class="ml-2">Sales Pitch</span>
        </label>
        <label class="flex items-center justify-center cursor-pointer border rounded" 
               [ngClass]="{
                 ' border-gray-300': productForm.get('moduleType').value !== 'product-queries',
                 'bg-purple text-white border-transparent': productForm.get('moduleType').value === 'product-queries'
               }">
          <input type="radio" (change)="onSubmit()" formControlName="moduleType" value="product-queries" class="form-radio">
          <span class="ml-2">Product Queries</span>
        </label>
      </div>
      <div *ngIf="productForm.get('moduleType')?.invalid && productForm.get('moduleType')?.touched" class="text-red-500 text-center mt-4">
        Please select anyone
      </div>
      
    </div>
  </form>
  