import { Component, ElementRef, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-sales-pitch',
  templateUrl: './sales-pitch.component.html',
  styleUrls: ['./sales-pitch.component.css']
})
export class SalesPitchComponent {

  activeTab: string = 'start';
  selectedProduct: any;
  selectedProductType: string = "";
  showSalesPitchModule: boolean = false;
  lifeCustomerDetails: any;
  healthCustomerDetails: any;
  display: boolean = false;
  reason1: boolean = false;
  reason2: boolean = false;
  reason3: boolean = false;
  reason4: boolean = false;
  others: boolean = false;
  feedback: string = '';
  pointersDisliked: boolean = false;
  pointersLiked: boolean = false;
  points = [];
  otherPoints = []
  paginatedPoints = [];
  currentPage = 0;
  pointsPerPage = 6;
  totalPages = [];
  pitchLoading: boolean = true;
  age: any = 0;
  isSkipped: boolean = false;
  isEditing = false;
  profileFilled = false;
  inputValue = '';
  compareProductsOptions: any = [];
  selectedProductToCompare: string = "";
  selectedCompanyToCompare: string = "";
  isComparePopupOpen: boolean = false;
  isComparisionPointsLoading: boolean = false;
  comparisionPoints: any = [];
  generatedPayloadForSalesPitch: any;

  constructor(private commonService: CommonService, private apiService: ApiService,
    private eRef: ElementRef) {
    commonService.productSelection.subscribe((product) => {
      this.selectedProduct = product;
    })
    this.selectedProductType = this.selectedProduct.productType;
    if (this.selectedProductType === 'life') {
      commonService.lifeProducts.subscribe((products) => {
        this.compareProductsOptions = products.filter(x => x.product != this.selectedProduct.product)
      })
    }
    else {
      commonService.healthProducts.subscribe((products) => {
        this.compareProductsOptions = products.filter(x => x.product != this.selectedProduct.product)
      })
    }
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  customerDetailsForHealth(event: any) {
    if (!event.isSkipped) {
      this.resetSessionId();
      this.isSkipped = false;
      this.showSalesPitchModule = true;
      this.healthCustomerDetails = event;
      localStorage.setItem("customerProfileForm",this.healthCustomerDetails);
      let self = this.healthCustomerDetails.members.filter(m => m.relationship === 'self')
      if (self.length > 0) {
        this.age = self[0].age;
      }
      let dependants = "";
      if (this.healthCustomerDetails.members.length > 0) {
        dependants = this.getCommaSeperatedString(this.getSelectedMembers(this.healthCustomerDetails.members));
      }
      let diseases = "";
      if (this.healthCustomerDetails.healthConditions.length > 0) {
        const diseases = this.getCommaSeperatedString(this.getStringValues(this.healthCustomerDetails.healthConditions));
      }
      const existingInsurance = this.getInsuranceString();
      let payLoad = {
        gender: this.healthCustomerDetails.gender,
        dependants: dependants,
        diseases: diseases,
        existing_insurance: existingInsurance,
        location: this.selectedProduct.location,
        session_id: sessionStorage.getItem('sessionId'),
        ...(this.age != 0 && { age: this.age })
      }
      this.generatedPayloadForSalesPitch = payLoad;
      this.apiService.getSalesPitchForHealth(payLoad, this.selectedProduct.company, this.selectedProduct.product).subscribe({
        next: (res) => {
          this.commonService.salesPointers.next(res);
          const parser = new DOMParser();
          let doc = parser.parseFromString(res.pitch_html, 'text/html');
          const highlyEffectiveItems = doc.querySelectorAll('p + ol:nth-of-type(1) li');
          const othersItems = doc.querySelectorAll('p + ol:nth-of-type(2) li');
          const highlyEffective = Array.from(highlyEffectiveItems).map(li => li.innerHTML.trim());
          const others = Array.from(othersItems).map(li => li.innerHTML.trim());
          this.points = highlyEffective;
          this.otherPoints = others;
          this.paginatePoints();
          this.calculateTotalPages();
          this.pitchLoading = false;
        },
        error: (err) => {

        }
      })
    }
    else {
      this.isSkipped = true;
      this.startEditing();
    }
  }

  customerDetailsForLife(event: any) {
    if (!event.isSkipped) {
      localStorage.setItem("customerProfileForm",event);
      this.resetSessionId();
      this.isSkipped = false;
      this.showSalesPitchModule = true;
      this.lifeCustomerDetails = event;
      let familyMembers = "";
      if (this.lifeCustomerDetails?.familyMembers.length > 0) {
        familyMembers = this.getCommaSeperatedString(this.getStringValues(this.lifeCustomerDetails?.familyMembers));
      }
      let goals = "";
      if (this.lifeCustomerDetails?.lifeGoals.length > 0) {
        goals = this.getCommaSeperatedString(this.getStringValues(this.lifeCustomerDetails?.lifeGoals))
      }
      let payload = {
        age: this.lifeCustomerDetails.age,
        gender: this.lifeCustomerDetails.gender,
        occupation: this.lifeCustomerDetails.occupation,
        family_members: familyMembers,
        expected_retirement_age: this.lifeCustomerDetails.retirementAge,
        annual_income: this.lifeCustomerDetails.annualIncome,
        annual_expenditure: this.lifeCustomerDetails.annualExpenditure,
        investments: this.lifeCustomerDetails.investments,
        outstanding_loans: this.lifeCustomerDetails.outstandingLoans,
        life_goals: goals,
        amount_needed: this.lifeCustomerDetails.amountNeeded,
        existing_insurance: this.lifeCustomerDetails.healthInsurance,
        coverage: this.lifeCustomerDetails.lifeInsuranceCoverage,
        location: this.lifeCustomerDetails.location,
        session_id: sessionStorage.getItem('sessionId')
      }
      this.generatedPayloadForSalesPitch = payload;
      this.apiService.getSalesPitchForLife(payload, this.selectedProduct.company, this.selectedProduct.product).subscribe({
        next: (res) => {
          this.commonService.salesPointers.next(res);
          const parser = new DOMParser();
          let doc = parser.parseFromString(res.pitch_html, 'text/html');
          const highlyEffectiveItems = doc.querySelectorAll('p + ol:nth-of-type(1) li');
          const othersItems = doc.querySelectorAll('p + ol:nth-of-type(2) li');
          const highlyEffective = Array.from(highlyEffectiveItems).map(li => li.innerHTML.trim());
          const others = Array.from(othersItems).map(li => li.innerHTML.trim());
          this.points = highlyEffective;
          this.otherPoints = others;
          this.pitchLoading = false;
          this.paginatePoints();
          this.calculateTotalPages();
        },
        error: (err) => {

        }
      })
    }
    else {
      this.isSkipped = true;
      this.startEditing();
    }
  }

  onSubmit() {
  }

  redirectToProductQueries(){
    this.commonService.history.next("/vigi/sales-pitch");
  }

  showDialog() {
    this.display = true;
  }

  getStringValues(items) {
    return items.map(item => item.item_text);
  }

  getCommaSeperatedString(values): string {
    if (values.length === 0) {
      return '';
    }
    if (values.length === 1) {
      return values[0];
    }
    if (values.length === 2) {
      return values.join(' and ');
    }
    const lastGoal = values.pop();
    return values.join(', ') + ', and ' + lastGoal;
  }

  getSelectedMembers(object: any) {
    return object.map(member => member.relationship);
  }

  getInsuranceString(): string {
    if (this.healthCustomerDetails.insuranceNone) {
      return 'no';
    }
    if (this.healthCustomerDetails.insuranceGroup && this.healthCustomerDetails.insuranceRetail) {
      return 'Group and Individual';
    }
    if (this.healthCustomerDetails.insuranceGroup) {
      return 'group';
    }
    if (this.healthCustomerDetails.insuranceRetail) {
      return 'individual';
    }
    return 'Insurance status unknown';
  }

  isDropdownOpen = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  redirectToCustomerDetails() {
    this.showSalesPitchModule = false;
    this.profileFilled = false;
    this.isSkipped = false;
    this.paginatedPoints = [];
    this.inputValue = '';
  }

  paginatePoints() {
    this.paginatedPoints = [];
    for (let i = 0; i < this.points.length; i += this.pointsPerPage) {
      this.paginatedPoints.push(this.points.slice(i, i + this.pointsPerPage));
    }
  }

  paginateOtherPoints() {
    this.paginatedPoints = [];
    for (let i = 0; i < this.otherPoints.length; i += this.pointsPerPage) {
      this.paginatedPoints.push(this.otherPoints.slice(i, i + this.pointsPerPage));
    }
  }

  calculateTotalPages() {
    this.totalPages = Array(Math.ceil(this.points.length / this.pointsPerPage)).fill(0).map((x, i) => i);
    this.currentPage = 0;
  }

  calculateTotalPagesForOtherPoints() {
    this.totalPages = Array(Math.ceil(this.otherPoints.length / this.pointsPerPage)).fill(0).map((x, i) => i);
    this.currentPage = 0;
  }

  nextPage() {
    if (this.currentPage < this.paginatedPoints.length - 1) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
  }

  onOthersClicked() {
    this.paginateOtherPoints();
    this.calculateTotalPagesForOtherPoints();
  }

  onStartClicked() {
    this.paginatePoints();
    this.calculateTotalPages();
  }

  startEditing() {
    this.isEditing = true;
    setTimeout(() => {
      const inputElement = document.querySelector('textarea') as HTMLTextAreaElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 0);
  }

  send() {
    localStorage.setItem("customerProfileDetails",this.inputValue);
    this.resetSessionId();
    this.profileFilled = true
    this.isEditing = false;
    this.pitchLoading = true;
    this.paginatedPoints = [];
    this.selectedProductToCompare = "";
    this.selectedCompanyToCompare = "";
    const customer_profile = this.inputValue;
    if (this.selectedProductType === 'life') {
      this.apiService.getSalesPitchForLifeWithProfie(customer_profile, this.selectedProduct.company, this.selectedProduct.product).subscribe({
        next: (res) => {
          this.commonService.salesPointers.next(res);
          const parser = new DOMParser();
          let doc = parser.parseFromString(res.pitch_html, 'text/html');
          const highlyEffectiveItems = doc.querySelectorAll('p + ol:nth-of-type(1) li');
          const othersItems = doc.querySelectorAll('p + ol:nth-of-type(2) li');
          const highlyEffective = Array.from(highlyEffectiveItems).map(li => li.innerHTML.trim());
          const others = Array.from(othersItems).map(li => li.innerHTML.trim());
          this.points = highlyEffective;
          this.otherPoints = others;
          this.pitchLoading = false;
          this.paginatePoints();
          this.calculateTotalPages();
        },
        error: (err) => {

        }
      })
    }
    else {
      this.apiService.getSalesPitchForHealthWithProfile(customer_profile, this.selectedProduct.company, this.selectedProduct.product).subscribe({
        next: (res) => {
          this.commonService.salesPointers.next(res);
          const parser = new DOMParser();
          let doc = parser.parseFromString(res.pitch_html, 'text/html');
          const highlyEffectiveItems = doc.querySelectorAll('p + ol:nth-of-type(1) li');
          const othersItems = doc.querySelectorAll('p + ol:nth-of-type(2) li');
          const highlyEffective = Array.from(highlyEffectiveItems).map(li => li.innerHTML.trim());
          const others = Array.from(othersItems).map(li => li.innerHTML.trim());
          this.points = highlyEffective;
          this.otherPoints = others;
          this.paginatePoints();
          this.calculateTotalPages();
          this.pitchLoading = false;
        },
        error: (err) => {

        }
      })
    }
  }

  adjustTextareaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';  // Reset the height to auto
    textarea.style.height = textarea.scrollHeight + 'px';  // Set it to the scroll height

    // Ensure the outer container grows with the textarea
    const container = textarea.closest('.profile-container') as HTMLElement;
    if (container) {
      container.style.height = 'auto';  // Reset the container height to auto
      container.style.height = container.scrollHeight + 30 + 'px';  // Set it to the scroll height
    }
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.navigation-options') || target.closest('.toggle-button');

    if (!clickedInside && this.isDropdownOpen || this.isCompareDropdownOpen) {
      this.isDropdownOpen = false;
      this.isCompareDropdownOpen = false;
      this.changeButtonVisibility(false);
    }
  }

  isCompareDropdownOpen = false;

  toggleCompareDropdown(event: Event) {
    event.stopPropagation();
    if (this.isCompareDropdownOpen)
      this.changeButtonVisibility(false);
    else
      this.changeButtonVisibility(true);
    this.isCompareDropdownOpen = !this.isCompareDropdownOpen;
  }

  productClicked(product: any) {
    this.selectedProductToCompare = product.product;
    this.selectedCompanyToCompare = product.company;
    this.isCompareDropdownOpen = false;
    this.changeButtonVisibility(false);
  }

  openComparePopup() {
    this.isComparePopupOpen = true;
    this.comparisionPoints = [];
    const customer_profile = this.inputValue;
    this.isComparisionPointsLoading = true;
    if (this.isSkipped) {
      this.apiService.getComparisonSummaryWithProfile(customer_profile, this.selectedProduct.company, this.selectedProduct.product
        , this.selectedProductType, this.selectedProductToCompare, this.selectedCompanyToCompare).subscribe({
          next: (res) => {
            const parser = new DOMParser();
            let doc = parser.parseFromString(res.comparison, 'text/html');
            this.comparisionPoints = Array.from(doc.querySelectorAll('p + ol:nth-of-type(1) li')).map(li => li.outerHTML);
            this.isComparisionPointsLoading = false;
          },
          error: (err) => {

          }
        })
    }
    else {
      this.generatedPayloadForSalesPitch = {
        ...this.generatedPayloadForSalesPitch,
        product2: this.selectedProductToCompare,
        company2: this.selectedCompanyToCompare
      };
      this.apiService.getComparisonSummaryWithFormDetails(this.generatedPayloadForSalesPitch, this.selectedProduct.company, this.selectedProduct.product
        , this.selectedProductType, this.selectedProductToCompare, this.selectedCompanyToCompare
      ).subscribe({
        next: (res) => {
          const parser = new DOMParser();
          let doc = parser.parseFromString(res.comparison, 'text/html');
          this.comparisionPoints = Array.from(doc.querySelectorAll('p + ol:nth-of-type(1) li')).map(li => li.outerHTML);
          this.isComparisionPointsLoading = false;
        },
        error: (res) => {

        }
      })
    }
  }

  changeButtonVisibility(flag: boolean) {
    let element = document.getElementById("compare-button") as HTMLElement;
    if (flag)
      element.style.zIndex = '10';
    else
      element.style.zIndex = '0';
  }

  toggleEditingMode(){
    this.showSalesPitchModule = false;
    this.isSkipped = true;
    this.profileFilled = false;
    this.paginatedPoints = [];
    this.otherPoints = [];
    this.selectedProductToCompare = "";
    this.selectedCompanyToCompare = "";
  }

  resetSessionId() {
    sessionStorage.removeItem('sessionId'); // Remove the current session ID
    const newSessionId = this.generateRandomString(16); // Generate a new random session ID
    sessionStorage.setItem('sessionId', newSessionId); // Set the new session ID
  }

  generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
