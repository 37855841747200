<header>
  <div class="container mx-auto flex items-center justify-between">
    <!-- Logo and Name -->
    <div class="flex items-center">
      <button routerLink="/vigi" class="focus:outline-none">
        <svg class="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5M12 19l-7-7 7-7"></path>
        </svg>
      </button>
      <span class="text-xl ml-3 font-bold">Sales pitch</span>
    </div>
    <!-- Kebab Icon -->
    <div class="flex items-center">
      <button (onblur)="toggleDropdown()" (click)="toggleDropdown()" class="focus:outline-none toggle-button">
        <img class="h-6 w-6" src="../../../../assets/icons/menu.png" />
      </button>
      <div [ngClass]="{'dropdown-open': isDropdownOpen}" class="header-wrapper absolute bg-white text-black rounded-md shadow-lg overflow-hidden">
        <div class="navigation-options">
          <span routerLink="/home" class="block px-4 py-2 text-sm">Exit</span>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="title-section h-10 flex items-center">
  <span class="ml-6">Customer profile details</span>
</div>
<div class="health-form" [formGroup]="membersForm">
  <!-- Gender Container -->
  <div class="flex flex-col">
    <label class="block header-label">Gender</label>
    <div class="container flex gender-toggle">
      <label class="inline-flex product-toggle items-center cursor-pointer justify-center">
        <span [ngClass]="{
          'bg-purple text-white': selectedGender === 'male',
          'bg-white text-black border border-l-gray-50': selectedGender !== 'male'
        }" class="px-4 py-2 text-center rounded-l-md" (click)="selectGender('male')">
          Male
        </span>
        <span [ngClass]="{
          'bg-purple text-white': selectedGender === 'female',
          'bg-white text-black border border-l-gray-50': selectedGender !== 'female'
        }" class="px-4 py-2 text-center" (click)="selectGender('female')">
          Female
        </span>
        <span [ngClass]="{
          'bg-purple text-white': selectedGender === 'others',
          'bg-white text-black border border-l-gray-50': selectedGender !== 'others'
        }" class="px-4 py-2 text-center rounded-r-md" (click)="selectGender('others')">
          Others
        </span>
      </label>
    </div>
  </div>


  <!-- Members to be Covered -->
  <div class="mt-4">
    <label class="block header-label">Members to be covered</label>
    <div class="relative members-input-container">
      <button type="button" (click)="decrementMembers()"
        class="absolute left-1 top-2/3 transform -translate-y-1/2">-</button>
      <input [readonly]="true" type="number" class="w-20 px-2 py-1 text-center" formControlName="membersCount">
      <button type="button" (click)="incrementMembers()"
        class="absolute right-1 top-2/3 transform -translate-y-1/2">+</button>
    </div>
  </div>

  <!-- Dynamic Relationships Container -->
  <div *ngFor="let member of getMembersControls(); let i = index" class="mt-4 flex items-center space-x-4">
    <div class="flex flex-col w-36 relationship-dropdown">
      <label class="block header-label">Relationship</label>
      <p-dropdown [options]="relationshipOptions"
        [formControl]="membersForm.get('members')['controls'][i].get('relationship')"
        [style]="{ width: '100%' }"></p-dropdown>
    </div>
    <div class="relative age-input-container">
      <label class="block header-label">Age</label>
      <button type="button" (click)="decrementAge(i)"
        class="absolute left-1 top-3/4 transform -translate-y-1/2">-</button>
      <input type="number" class="w-full px-2 py-1 text-center"
        [formControl]="membersForm.get('members')['controls'][i].get('age')">
      <button type="button" (click)="incrementAge(i)"
        class="absolute right-1 top-3/4 transform -translate-y-1/2">+</button>
    </div>
  </div>

  <!-- Existing Health Conditions Multi-Select -->
  <div class="mt-4">
    <div class="flex flex-col health-conditions-dropdown">
      <label class="block header-label">Health Conditions</label>
      <div class="container flex flex-wrap justify-center">
        <div *ngFor="let condition of healthConditions" (click)="toggleConditionSelection(condition.item_id)" [ngClass]="{
               'bg-purple text-white': selectedConditions.includes(condition.item_id),
               'bg-white text-black': !selectedConditions.includes(condition.item_id),
               'py-2': condition.item_text.length<15
             }" class="px-2 text-center cursor-pointer border multi-select-option-container">
          {{ condition.item_text }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-4">
    <label class="block header-label">Existing Health Insurance</label>
    <div class="container flex">
      <label class="inline-flex insurance-toggle items-center cursor-pointer">
        <span [ngClass]="{
              'bg-purple text-white': selectedInsurance === 'group',
              'bg-white text-black border border-l-gray-50': selectedInsurance !== 'group'
            }" class="text-center rounded-l-md px-4 py-1 cursor-pointer" (click)="selectInsurance('group')">
          Group
        </span>
        <span [ngClass]="{
              'bg-purple text-white': selectedInsurance === 'retail',
              'bg-white text-black border border-l-gray-50': selectedInsurance !== 'retail'
            }" class="text-center px-4 py-1 cursor-pointer" (click)="selectInsurance('retail')">
          Individual
        </span>
        <span [ngClass]="{
              'bg-purple text-white': selectedInsurance === 'none',
              'bg-white text-black border border-l-gray-50': selectedInsurance !== 'none'
            }" class="text-center rounded-r-md px-4 py-1 cursor-pointer" (click)="selectInsurance('none')">
          None
        </span>
      </label>
    </div>
  </div>


  <!-- Location Radio Buttons -->
  <div class="mt-4">
    <label class="block header-label">Location</label>
    <div class="flex justify-between items-center location-toggle">
      <label class="inline-flex items-center cursor-pointer justify-center">
        <span [ngClass]="{
            'bg-purple text-white': selectedLocation === 'top-city',
            'bg-white text-black border border-l-gray-50': selectedLocation !== 'top-city'
          }" class="px-2 text-center rounded-l-md" (click)="selectLocation('top-city')">
          Top city
        </span>
        <span [ngClass]="{
            'bg-purple text-white': selectedLocation === 'small-city',
            'bg-white text-black border border-l-gray-50': selectedLocation !== 'small-city'
          }" class="px-2 text-center" (click)="selectLocation('small-city')">
          Small city/Town
        </span>
        <span [ngClass]="{
            'bg-purple text-white': selectedLocation === 'rural',
            'bg-white text-black border border-l-gray-50': selectedLocation !== 'rural'
          }" class="px-2 text-center rounded-r-md" (click)="selectLocation('rural')">
          Rural area
        </span>
      </label>
    </div>
  </div>


  <div class="mt-8 text-center flex items-center">
    <button (click)="onSubmit(false)" class="custom-button mx-auto">Submit</button>
    <p (click)="onSubmit(true)" class="absolute right-8 font-semibold font-purple">Skip to profile</p>
  </div>

  <div>
    <!-- Gender Error Message -->
    <div
      *ngIf="membersForm.get('gender').invalid && (membersForm.get('gender').dirty || membersForm.get('gender').touched)"
      class="col-span-2 text-red-500">
      Please select a gender.
    </div>

    <!-- Members Count Error Message -->
    <div
      *ngIf="membersForm.get('membersCount').invalid && (membersForm.get('membersCount').dirty || membersForm.get('membersCount').touched)"
      class="col-span-2 text-red-500">
      Please enter valid member count.
    </div>

    <!-- Member Relationship and Age Error Messages -->
    <div *ngFor="let member of getMembersControls(); let i = index" class="col-span-1">
      <div
        *ngIf="membersForm.get('members')['controls'][i].get('relationship').invalid && (membersForm.get('members')['controls'][i].get('relationship').dirty || membersForm.get('members')['controls'][i].get('relationship').touched)"
        class="text-red-500">
        Please select the relationship.
      </div>
      <div
        *ngIf="membersForm.get('members')['controls'][i].get('age').invalid && (membersForm.get('members')['controls'][i].get('age').dirty || membersForm.get('members')['controls'][i].get('age').touched)"
        class="text-red-500">
        Please enter a valid age (0-99).
      </div>
    </div>

    <!-- Health Conditions Error Message -->
    <div
      *ngIf="membersForm.get('healthConditions').invalid && (membersForm.get('healthConditions').dirty || membersForm.get('healthConditions').touched)"
      class="col-span-2 text-red-500">
      Please choose valid health conditions.
    </div>

    <!-- Location Error Message -->
    <div
      *ngIf="membersForm.get('location').invalid && (membersForm.get('location').dirty || membersForm.get('location').touched)"
      class="col-span-2 text-red-500">
      Please select a location.
    </div>

    <!-- Insurance Type Error Message -->
    <div
      *ngIf="membersForm.get('insuranceGroup').invalid && membersForm.get('insuranceRetail').invalid && membersForm.get('insuranceNone').invalid && (membersForm.get('insuranceGroup').dirty || membersForm.get('insuranceRetail').dirty || membersForm.get('insuranceNone').dirty || membersForm.get('insuranceGroup').touched || membersForm.get('insuranceRetail').touched || membersForm.get('insuranceNone').touched)"
      class="col-span-2 text-red-500">
      Please select an insurance type.
    </div>
  </div>

</div>