import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-error-dialog',
  template: `
  <p>An error occurred!!!</p>
  <p>Please try again<p>
`,
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent {
  constructor( private ref: DynamicDialogRef) { }

  close() {
    this.ref.close();
  }
}
