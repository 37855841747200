<header>
    <div class="header flex items-center">
        <span class="font-bold">Insurance Sales App</span>
    </div>
</header>
<main>
    <img src="../../../assets/icons/blurred-bg.png" />
    <div class="vigi-icon-container">
        <img routerLink="/vigi" class="vigi-icon mx-auto" src="../../../assets/icons/bot-icon.png" />
        <span class="font-medium text-xs">VIGI</span>
    </div>
</main>