import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[commaFormatter]'
})
export class CommaFormatterDirective {

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    const inputElement = this.el.nativeElement;

    // Remove all non-digit characters
    let value = inputElement.value.replace(/[^0-9]/g, '');
    
    // Format the number according to the Indian numbering system
    value = this.formatToIndianNumberingSystem(value);

    // Set the formatted value to the input element
    inputElement.value = value;

    // Update the form control value without commas
    this.control.control.setValue(value);

    event.stopPropagation();
  }

  private formatToIndianNumberingSystem(value: string): string {
    let integerPart = value;
    let lastThree = integerPart.substring(integerPart.length - 3);
    let otherNumbers = integerPart.substring(0, integerPart.length - 3);

    if (otherNumbers !== '') {
      lastThree = ',' + lastThree;
    }
    let formattedNumber = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

    return formattedNumber;
  }
}
