import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-vigi',
  templateUrl: './vigi.component.html',
  styleUrls: ['./vigi.component.css']
})
export class VigiComponent {

  showProductSelection: boolean = true;
  title: string = "VIGI";
  selectedProduct: any;

  constructor(private router: Router, private commonService: CommonService) {
    commonService.productSelection.subscribe((product) => {
      this.selectedProduct = product
    })
  }
}
