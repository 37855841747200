import { Injectable } from '@angular/core';
import { API } from '../view-models/Constants';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public BaseURL = API.prodBaseURL;
  public apiKey = "msnd19829ASDn8786SS"

  constructor(public http: HttpClient) { }

  httpGetHelp(apiURL: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.get<any>(this.BaseURL + apiURL, { headers });
  }

  httpPostHelp(apiURL: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.apiKey}`
    });
    return this.http.post<any>(this.BaseURL + apiURL, data, { headers });
  }

  getPitch() {
    const url = "/pitch/life/LIC/Product1?age=30&dependants=2&city=NewYork&diseases=none&session_id=12345";
    return this.httpGetHelp(url);
  }

  getProducts(category: string) {
    let api = "/products/" + category + "/";
    return this.httpGetHelp(api);
  }

  getSalesPitchForHealth(payload: any, company: string, product: string) {
    const url = "/pitch/health/" + company + "/" + product
    return this.httpPostHelp(url, payload);
  }

  getSalesPitchForLife(payload: any, company: string, product: string) {
    const url = "/pitch/life/" + company + "/" + product
    return this.httpPostHelp(url, payload);
  }

  getProductResponse(payload: any, company: string, product: string, category: string) {
    const url = "/chat/" + category + "/" + company + "/" + product;
    return this.httpPostHelp(url, payload);
  }

  getSalesPitchForHealthWithProfile(customer_profile: any, company: string, product: string) {
    const url = "/pitch/health/" + company + "/" + product + "/" + "?customer_profile=" + customer_profile
    return this.httpGetHelp(url);
  }

  getSalesPitchForLifeWithProfie(customer_profile: any, company: string, product: string) {
    const url = "/pitch/life/" + company + "/" + product + "?customer_profile=" + customer_profile
    return this.httpGetHelp(url);
  }

  getComparisonSummaryWithProfile(customer_profile: any, company: string, product: string, category: string
    , product2: string, company2: string
  ) {
    const url = "/compare/" + category + "/" + company + "/" + product + "?customer_profile=" + customer_profile
      + "&product2=" + product2 + "&company2=" + company2;
    return this.httpGetHelp(url);
  }

  getComparisonSummaryWithFormDetails(payload: any, company: string, product: string, category: string,
    product2: string, company2: string) {
      const url = "/compare/" + category + "/" + company + "/" + product + "?&product2=" + product2 + "&company2=" + company2;
      return this.httpPostHelp(url, payload);
  }

  addFeedback(payload: any){
    const url = "/feedback";
    return this.httpPostHelp(url,payload);
  }
}
