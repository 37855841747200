import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
@Component({
  selector: 'app-product-details-health',
  templateUrl: './product-details-health.component.html',
  styleUrls: ['./product-details-health.component.css']
})
export class ProductDetailsHealthComponent {
  @Input() selectedProduct: any;
  selectedGender: string = 'male';
  @Output() customerDetails = new EventEmitter();
  membersForm: FormGroup;
  relationshipOptions = [
    { label: 'Self', value: 'self' },
    { label: 'Spouse', value: 'spouse' },
    { label: 'Child 1', value: 'child 1' },
    { label: 'Child 2', value: 'child 2' },
    { label: 'Child 3', value: 'child 3' },
    { label: 'Mother', value: 'mother' },
    { label: 'Father', value: 'father' },
    { label: 'Mother in law', value: 'mother in law' },
    { label: 'Father in law', value: 'father in law' },
    { label: 'Brother 1', value: 'brother 1' },
    { label: 'Brother 2', value: 'brother 2' },
    { label: 'Sister 1', value: 'sister 1' },
    { label: 'Sister 2', value: 'sister 2' }
  ];
  healthConditions = [
    { item_id: 1, item_text: 'Diabetes' },
    { item_id: 2, item_text: 'Blood pressure' },
    { item_id: 3, item_text: 'Thyroid' },
    { item_id: 4, item_text: 'Asthma' },
    { item_id: 5, item_text: 'Surgery in the past' },
    { item_id: 6, item_text: 'Any other illness/condition' }
  ];

  selectedConditions: number[] = [];
  selectedLocation: string | null = 'top-city';
  selectedInsurance: string | null = 'group';
  dropdownSettings = {};
  constructor(private fb: FormBuilder) {
    this.membersForm = this.fb.group({
      gender: ['male'],
      membersCount: [1, [, Validators.min(1)]],
      members: this.fb.array([this.createMemberGroup()]),
      healthConditions: [[]],
      location: ['top-city'],
      isSkipped: [false],
      insuranceGroup: [true],
      insuranceRetail: [false],
      insuranceNone: [false]
    }, { validator: this.atLeastOneCheckboxValidator() }); // Apply the custom validator to the form group

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }

  createMemberGroup(): FormGroup {
    return this.fb.group({
      relationship: ['',],
      age: [, [, Validators.min(0)]]
    });
  }

  atLeastOneCheckboxValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const groupValue = this.membersForm?.get('insuranceGroup')?.value;
      const retailValue = this.membersForm?.get('insuranceRetail')?.value;
      const noneValue = this.membersForm?.get('insuranceNone')?.value;

      return groupValue || retailValue || noneValue ? null : { required: true };
    };
  }

  get members(): FormArray {
    return this.membersForm.get('members') as FormArray;
  }

  getMembersControls() {
    return this.members.controls;
  }

  incrementMembers() {
    const currentCount = this.membersForm.get('membersCount').value;
    this.membersForm.get('membersCount').setValue(currentCount + 1);
    this.addMember();
  }

  decrementMembers() {
    const currentCount = this.membersForm.get('membersCount').value;
    if (currentCount > 1) {
      this.membersForm.get('membersCount').setValue(currentCount - 1);
      this.removeMember();
    }
  }

  addMember() {
    this.members.push(this.fb.group({
      relationship: ['',], // Add required validator for relationship dropdown
      age: [0]
    }));
  }

  removeMember() {
    this.members.removeAt(this.members.length - 1);
  }

  incrementAge(index: number) {
    const ageControl = this.members.at(index).get('age');
    ageControl.setValue(ageControl.value + 1);
  }

  decrementAge(index: number) {
    const ageControl = this.members.at(index).get('age');
    if (ageControl.value > 0) {
      ageControl.setValue(ageControl.value - 1);
    }
  }

  onSubmit(isSkipped: boolean) {
    const conditions = this.selectedConditions.map(id => this.healthConditions.find(condition => condition.item_id === id));
    this.membersForm.get('healthConditions').setValue(conditions);
    if (isSkipped)
      this.membersForm.get('isSkipped').setValue(true);
    this.customerDetails.emit(this.membersForm.value)
    this.membersForm.markAllAsTouched();
  }

  isDropdownOpen = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectGender(gender: string) {
    this.selectedGender = gender;
    this.membersForm.get('gender').setValue(gender);
  }

  toggleConditionSelection(itemId: number) {
    if (this.selectedConditions.includes(itemId)) {
      this.selectedConditions = this.selectedConditions.filter(id => id !== itemId);
    } else {
      this.selectedConditions.push(itemId);
    }
  }

  selectInsurance(insuranceType: string) {
    this.selectedInsurance = insuranceType;
    this.updateFormControl(insuranceType);
  }

  updateFormControl(insuranceType: string) {
    // Assuming you are using a reactive form
    if (insuranceType === 'group') {
      this.membersForm.controls['insuranceGroup'].setValue(true);
      this.membersForm.controls['insuranceRetail'].setValue(false);
      this.membersForm.controls['insuranceNone'].setValue(false);
    } else if (insuranceType === 'retail') {
      this.membersForm.controls['insuranceGroup'].setValue(false);
      this.membersForm.controls['insuranceRetail'].setValue(true);
      this.membersForm.controls['insuranceNone'].setValue(false);
    } else if (insuranceType === 'none') {
      this.membersForm.controls['insuranceGroup'].setValue(false);
      this.membersForm.controls['insuranceRetail'].setValue(false);
      this.membersForm.controls['insuranceNone'].setValue(true);
    }
  }

  selectLocation(location: string) {
    this.selectedLocation = location;
    this.membersForm.controls['location'].setValue(location);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.navigation-options') || target.closest('.toggle-button');

    if (!clickedInside && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }
}
